body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    color: inherit;
    text-decoration: none
}

* {
    box-sizing: border-box
}

button {
    appearance: none;
    background: unset;
    border: none;
    color: unset;
    font: unset;
}

b {
    font-weight: 600;
}